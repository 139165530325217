@import './variables.scss';

.App {
  font-family: $main-font;
  color: $font-color;
  height: 100%;
  width: 100%;

  a, .ui.header, .ui.checkbox label {
    color: $font-color;
  }
  .circle {
    color: $circle-icon-color;
  }
  .check {
    color: $check-circle-color;
  }
  .check.circle.outline {
    color: $check-circle-color;
  }
  .stripe-fields {
    margin: 0;
    outline: 0;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    line-height: 1.21428571em;
    padding: .67857143em 1em;
    // font-size: 1em;
    background: #fff;
    border: 1px solid rgba(34,36,38,.15);
    color: $font-color ;
    border-radius: .28571429rem;
    -webkit-box-shadow: 0 0 0 0 transparent inset;
    box-shadow: 0 0 0 0 transparent inset;
    // -webkit-transition: color .1s ease,border-color .1s ease;
    // transition: color .1s ease,border-color .1s ease;
  }
}

.underline a{
  text-decoration: underline;
  color: $next-button-color;
}

// iframe {
//   width: 100%;
//   height: 80%;
// }


.StripeElement {
  font-family: $main-font;
  font-weight: 900 !important;
}

.seg-wrapper {
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
}

.ui.button, .ui.button:hover {
  width: 140px;
  height: 40px;
  padding-left: 5px;
  padding-right: 5px;
  color: #FFF;
}

.next-button {
  // margin: 0 0 0 20px !important;
  background: $next-button-color !important;
}
.ACH-button {
  margin: 20px 0 0 0 !important;
  background: $om-red !important;
}

.back-button {
  color: $back-button-color !important;
  background-color: #FFF !important;
  width: 70px !important;
}

.buttonPad {
  padding: 10px 20px 20px 0;
}

.equalize-height {
  min-height: 514px;
}
.tall-height {
  min-height: 600px;
}

.ui.basic.segment.home:hover {
  // background-color: $hover-blue
  cursor: default
}

.header-bg {
  min-height: 12vh;
  // max-height: 20vh;
  // height: 18vh;
  background-color: $om-red;
  color: #FFF; 
  width: 100%;
  p {
    font-weight: 600
  }
  a {
    color: #FFF
  }
}
.ui.grid {
  .row.header-row, .row.header-row-no-top-space {
    padding-bottom: 0px;
  }
  .row.header-row {
    padding-top: 7px;
  }
  .row.header-row-no-top-space {
    padding-top: 0px;
  }
}
.header-h2-margin {
  margin-bottom: 0px
}
div.four.wide.mobile.two.wide.tablet.two.wide.computer.right.floated.right.aligned.column {
  padding-right: 0px;
  margin-bottom: 1em;
}

.ui.selection.dropdown {
  padding-bottom: 7px;
  padding-top: 7px;
}
// div.two.fields > select {
//   margin-top: 4px !important;
// }
// div.two.fields {
//   .two-field-selector.field {
//     margin-top: 4px;
//   }
// }


.white-divide{
  border-bottom: 1px solid rgba(255,255,255, .75) !important
}

.inline {
  display: inline-block;
}
.divide-bottom {
  border-bottom: 1px solid $back-button-color;
}
.divide-top-with-divider {
  border-top: 3px solid $back-button-color;
  margin-top: 10px;
  padding-top: 20px;
}
.divide-top-no-divider {
  margin-top: 10px;
  padding-top: 20px;
}
.terms-policy {
  a {
    color: #2CBCB5;
    text-decoration: underline !important;
  }
}
.impact-top-spacing {
  margin-top: 10px;
}

.full-height-flex {
  display: flex;
  min-height: 100%;
  flex-direction: column;
}

.non-footer-flex-content {
  flex: 1;
  height:100%;
}

.vert-center-parent{
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  flex-wrap: wrap;
  height: 55vh;
}
.vert-center-login{
  background-image: url('./images/Login-Background-1.png');
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: relative;
  flex-wrap: wrap;
  height: 100%;
}
.vert-center-child {
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}

div.ui.segment.service-segment {
  background-color: #e4f6ff;
  box-shadow: none;
  border: none;
}

div.ui.card.login-card {
  padding: 1.5em 0.5em;
  opacity: 0.95;
  // .header {
  //   margin-bottom: 1.5em;
  // }
  .forms-space {
    margin: 2em 0em;
  }
}

.help-text {
  color: $om-red;
  margin-bottom: 10px;
  h5 {
    margin-top: 0;
  }
}

.schedule-section {
  margin-top: 20px;
  h3 {
    color: $om-red;
  }
}
.ui.inverted.segment.help-segment {
  background: #EE2D55;
  .underline a {
    color: rgba(255,255,255,.9);
  }
}

// .hundo-height {
//   height: 100%;
// }


.ui.grid.cc-forms {
  .row {
    padding: 0px;
    margin-bottom: 12px;
    .cc-acct-info {
      padding-top: 7px;
    }
  }
}
.billing-info {
  margin-top: 3em;
  margin-bottom: .5em;
}
.ui.horizontal.segments {
  background: #7AC24D;
  color: #FFF;
  text-align: center;
  border: none;
}
.ui.horizontal.segments:not(.stackable)>.segment:first-child {
  border-left: none;
}
.ui.horizontal.segments>.segment {
  border-left: 1px solid rgb(255,255,255);
}

i.big.exclamation {
  color: $om-red;
}

.service-drivers {
  margin-bottom: 10px;
  p {
    margin-bottom: 7px;
  }
}

.radio-group-bottom-space {
  margin-bottom: 5px !important;
}
.radio-label{
  display: block;
  margin: 0 0 .28571429rem 0;
  color: rgba(0,0,0,.87);
  font-size: .92857143em;
  font-weight: 700;
  text-transform: none;
}

.price-plan {
  margin-bottom: 10px;
}
.billing-info-segment {
  margin: 1em 0em !important;
  
}

.footer-space {
  margin-top: 3em;
  .footer-pad {
    padding-top: 1em;
    .footer-left-stuff {
      padding-left: 0px !important;
    }
    .footer-right-stuff {
      padding-right:0px !important;
    }
  }
}


//media queries 
@media screen and (max-width: 380px) {
  .ui.teal.small.progress {
    margin-left: 10px;
  }
  .header-bg {
    min-height: 16vh;
  }
}
@media screen and (min-width: 1824px) {
  .header-bg {
    min-height: 10vh;
  }
}


@media screen and (max-width: 425px) {
  .ui.embed {
    height: 60vh;
  }
}
@media screen and (min-width: 1024px) {
  .header-bg{
    .progress-label {
      padding-right: 0px !important;
    }
    .progress-bar {
      padding-left: 0px !important;
    }
  }
  .tos-check-copy {
    padding-left: 4px !important;
  }

  // .communication-spacing {
  //   margin-top: 5em;
  // }
}

// @keyframes App-logo-spin {
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// }